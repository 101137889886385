//Возвращает false в случаее прохождения валидации, в случае несоответствия возвращает текст ошибки(true)

const validate = (inputType, value) => {
    switch (inputType) {
        case 'name':
            return value.match(/^[А-яёЁ]+$/)
                ? false
                : 'Некорректное имя. Допустимы только русские символы.'
        case 'email':
            return value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)
                ? false
                : 'Некорректный E-mail.'
        case 'phone':
            return value.match(/^8[0-9]{10}$/)
                ? false
                : 'Неправильный номер. Используйте формат 89001112233'
        case 'password':
            return value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)
                ? false
                : 'Пароль должен содержать не менее 8 символов и содержать не менее одной заглавной буквы и цифры  '
        case 'number':
            return value.match(/^\d+$/)
                ? false
                : 'Допустимы только цифры'
        default:
            return true
    }
}

export default validate


